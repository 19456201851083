export const EMPLOYEE_CHANGE_PASSWORD = "EMPLOYEE_CHANGE_PASSWORD";
export const EMPLOYEE_CHANGE_PASSWORD_SUCCESS =
  "EMPLOYEE_CHANGE_PASSWORD_SUCCESS";
export const EMPLOYEE_CHANGE_PASSWORD_ERROR = "EMPLOYEE_CHANGE_PASSWORD_ERROR";

export const FETCH_BRANCH = "FETCH_BRANCH";
export const FETCH_BRANCH_SUCCESS = "FETCH_BRANCH_SUCCESS";
export const FETCH_BRANCH_ERROR = "FETCH_BRANCH_ERROR";

export const FETCH_TAXES = "FETCH_TAXES";
export const FETCH_TAXES_SUCCESS = "FETCH_TAXES_SUCCESS";
export const FETCH_TAXES_ERROR = "FETCH_TAXES_ERROR";

export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";
export const FETCH_GROUP_ERROR = "FETCH_GROUP_ERROR";

export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_ERROR = "FETCH_DEPARTMENT_ERROR";

export const CREATE_BRANCH = "CREATE_BRANCH";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_ERROR = "CREATE_BRANCH_ERROR";

export const CREATE_TAXES = "CREATE_TAXES";
export const CREATE_TAXES_SUCCESS = "CREATE_TAXES_SUCCESS";
export const CREATE_TAXES_ERROR = "CREATE_TAXES_ERROR";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_ERROR = "CREATE_DEPARTMENT_ERROR";

export const ADMIN_FETCH = "ADMIN_FETCH";
export const ADMIN_FETCH_SUCCESS = "ADMIN_FETCH_SUCCESS";
export const ADMIN_FETCH_ERROR = "ADMIN_FETCH_ERROR";

export const ADMIN_CHANGE_PASSWORD = "ADMIN_CHANGE_PASSWORD";
export const ADMIN_CHANGE_PASSWORD_SUCCESS = "ADMIN_CHANGE_PASSWORD_SUCCESS";
export const ADMIN_CHANGE_PASSWORD_ERROR = "ADMIN_CHANGE_PASSWORD_ERROR";

// group branch department

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_ERROR = "UPDATE_BRANCH_ERROR";

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_ERROR = "DELETE_BRANCH_ERROR";

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

// Customer POD essentails crud

export const CUSTOMERPOD_FETCH = "CUSTOMERPOD_FETCH";
export const CUSTOMERPOD_FETCH_SUCCESS = "CUSTOMERPOD_FETCH_SUCCESS";
export const CUSTOMERPOD_FETCH_ERROR = "CUSTOMERPOD_FETCH_ERROR";

export const CUSTOMERPOD_CREATE = "CUSTOMERPOD_CREATE";
export const CUSTOMERPOD_CREATE_SUCCESS = "CUSTOMERPOD_CREATE_SUCCESS";
export const CUSTOMERPOD_CREATE_ERROR = "CUSTOMERPOD_CREATE_ERROR";

export const CUSTOMERPOD_UPDATE = "CUSTOMERPOD_UPDATE";
export const CUSTOMERPOD_UPDATE_SUCCESS = "CUSTOMERPOD_UPDATE_SUCCESS";
export const CUSTOMERPOD_UPDATE_ERROR = "CUSTOMERPOD_UPDATE_ERROR";

export const CUSTOMERPOD_DELETE = "CUSTOMERPOD_DELETE";
export const CUSTOMERPOD_DELETE_SUCCESS = "CUSTOMERPOD_DELETE_SUCCESS";
export const CUSTOMERPOD_DELETE_ERROR = "CUSTOMERPOD_DELETE_ERROR";

// Customer Type

export const CUSTOMERTYPE_FETCH = "CUSTOMERTYPE_FETCH";
export const CUSTOMERTYPE_FETCH_SUCCESS = "CUSTOMERTYPE_FETCH_SUCCESS";
export const CUSTOMERTYPE_FETCH_ERROR = "CUSTOMERTYPE_FETCH_ERROR";

export const CUSTOMERTYPE_CREATE = "CUSTOMERTYPE_CREATE";
export const CUSTOMERTYPE_CREATE_SUCCESS = "CUSTOMERTYPE_CREATE_SUCCESS";
export const CUSTOMERTYPE_CREATE_ERROR = "CUSTOMERTYPE_CREATE_ERROR";

export const CUSTOMERTYPE_UPDATE = "CUSTOMERTYPE_UPDATE";
export const CUSTOMERTYPE_UPDATE_SUCCESS = "CUSTOMERTYPE_UPDATE_SUCCESS";
export const CUSTOMERTYPE_UPDATE_ERROR = "CUSTOMERTYPE_UPDATE_ERROR";

export const CUSTOMERTYPE_DELETE = "CUSTOMERTYPE_DELETE";
export const CUSTOMERTYPE_DELETE_SUCCESS = "CUSTOMERTYPE_DELETE_SUCCESS";
export const CUSTOMERTYPE_DELETE_ERROR = "CUSTOMERTYPE_DELETE_ERROR";

// vendor Type

export const VENDORTYPE_FETCH = "VENDORTYPE_FETCH";
export const VENDORTYPE_FETCH_SUCCESS = "VENDORTYPE_FETCH_SUCCESS";
export const VENDORTYPE_FETCH_ERROR = "VENDORTYPE_FETCH_ERROR";

export const VENDORTYPE_CREATE = "VENDORTYPE_CREATE";
export const VENDORTYPE_CREATE_SUCCESS = "VENDORTYPE_CREATE_SUCCESS";
export const VENDORTYPE_CREATE_ERROR = "VENDORTYPE_CREATE_ERROR";

export const VENDORTYPE_UPDATE = "VENDORTYPE_UPDATE";
export const VENDORTYPE_UPDATE_SUCCESS = "VENDORTYPE_UPDATE_SUCCESS";
export const VENDORTYPE_UPDATE_ERROR = "VENDORTYPE_UPDATE_ERROR";

export const VENDORTYPE_DELETE = "VENDORTYPE_DELETE";
export const VENDORTYPE_DELETE_SUCCESS = "VENDORTYPE_DELETE_SUCCESS";
export const VENDORTYPE_DELETE_ERROR = "VENDORTYPE_DELETE_ERROR";

// Document Type

export const DOCUMENTTYPE_FETCH = "DOCUMENTTYPE_FETCH";
export const DOCUMENTTYPE_FETCH_SUCCESS = "DOCUMENTTYPE_FETCH_SUCCESS";
export const DOCUMENTTYPE_FETCH_ERROR = "DOCUMENTTYPE_FETCH_ERROR";

export const DOCUMENTTYPE_CREATE = "DOCUMENTTYPE_CREATE";
export const DOCUMENTTYPE_CREATE_SUCCESS = "DOCUMENTTYPE_CREATE_SUCCESS";
export const DOCUMENTTYPE_CREATE_ERROR = "DOCUMENTTYPE_CREATE_ERROR";

export const DOCUMENTTYPE_UPDATE = "DOCUMENTTYPE_UPDATE";
export const DOCUMENTTYPE_UPDATE_SUCCESS = "DOCUMENTTYPE_UPDATE_SUCCESS";
export const DOCUMENTTYPE_UPDATE_ERROR = "DOCUMENTTYPE_UPDATE_ERROR";

export const DOCUMENTTYPE_DELETE = "DOCUMENTTYPE_DELETE";
export const DOCUMENTTYPE_DELETE_SUCCESS = "DOCUMENTTYPE_DELETE_SUCCESS";
export const DOCUMENTTYPE_DELETE_ERROR = "DOCUMENTTYPE_DELETE_ERROR";

// Load Type

export const LOADTYPE_FETCH = "LOADTYPE_FETCH";
export const LOADTYPE_FETCH_SUCCESS = "LOADTYPE_FETCH_SUCCESS";
export const LOADTYPE_FETCH_ERROR = "LOADTYPE_FETCH_ERROR";

export const LOADTYPE_CREATE = "LOADTYPE_CREATE";
export const LOADTYPE_CREATE_SUCCESS = "LOADTYPE_CREATE_SUCCESS";
export const LOADTYPE_CREATE_ERROR = "LOADTYPE_CREATE_ERROR";

export const LOADTYPE_UPDATE = "LOADTYPE_UPDATE";
export const LOADTYPE_UPDATE_SUCCESS = "LOADTYPE_UPDATE_SUCCESS";
export const LOADTYPE_UPDATE_ERROR = "LOADTYPE_UPDATE_ERROR";

export const LOADTYPE_DELETE = "LOADTYPE_DELETE";
export const LOADTYPE_DELETE_SUCCESS = "LOADTYPE_DELETE_SUCCESS";
export const LOADTYPE_DELETE_ERROR = "LOADTYPE_DELETE_ERROR";

// package Type

export const PACKAGETYPE_FETCH = "PACKAGETYPE_FETCH";
export const PACKAGETYPE_FETCH_SUCCESS = "PACKAGETYPE_FETCH_SUCCESS";
export const PACKAGETYPE_FETCH_ERROR = "PACKAGETYPE_FETCH_ERROR";

export const PACKAGETYPE_CREATE = "PACKAGETYPE_CREATE";
export const PACKAGETYPE_CREATE_SUCCESS = "PACKAGETYPE_CREATE_SUCCESS";
export const PACKAGETYPE_CREATE_ERROR = "PACKAGETYPE_CREATE_ERROR";

export const PACKAGETYPE_UPDATE = "PACKAGETYPE_UPDATE";
export const PACKAGETYPE_UPDATE_SUCCESS = "PACKAGETYPE_UPDATE_SUCCESS";
export const PACKAGETYPE_UPDATE_ERROR = "PACKAGETYPE_UPDATE_ERROR";

export const PACKAGETYPE_DELETE = "PACKAGETYPE_DELETE";
export const PACKAGETYPE_DELETE_SUCCESS = "PACKAGETYPE_DELETE_SUCCESS";
export const PACKAGETYPE_DELETE_ERROR = "PACKAGETYPE_DELETE_ERROR";

export const PROFILE_EDIT_INIT = "PROFILE_EDIT_INIT";
export const PROFILE_EDIT_ERROR = "PROFILE_EDIT_ERROR";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";

export const PROFILE_VIEW_INIT = "PROFILE_VIEW_INIT";
export const PROFILE_VIEW_ERROR = "PROFILE_VIEW_ERROR";
export const PROFILE_VIEW_SUCCESS = "PROFILE_VIEW_SUCCESS";

//COMODITY
export const COMMODITY_CREATE = "COMMODITY_CREATE";
export const COMMODITY_CREATE_SUCCESS = "COMMODITY_CREATE_SUCCESS";
export const COMMODITY_CREATE_ERROR = "COMODITY_CREATE_ERROR";

export const COMMODITY_FETCH = "COMMODITY_FETCH";
export const COMMODITY_FETCH_SUCCESS = "COMMODITY_FETCH_SUCCESS";
export const COMMODITY_FETCH_ERROR = "COMMODITY_FETCH_ERROR";

export const COMMODITY_UPDATE = "COMMODITY_UPDATE";
export const COMMODITY_UPDATE_SUCCESS = "COMMODITY_UPDATE_SUCCESS";
export const COMMODITY_UPDATE_ERROR = "COMMODITY_UPDATE_ERROR";

export const COMMODITY_DELETE = "COMMODITY_DELETE";
export const COMMODITY_DELETE_SUCCESS = "COMMODITY_DELETE_SUCCESS";
export const COMMODITY_DELETE_ERROR = "COMMODITY_DELETE_ERROR";

// Email Template

export const EMAIL_CREATE = "EMAIL_CREATE";
export const EMAIL_CREATE_SUCCESS = "EMAIL_CREATE_SUCCESS";
export const EMAIL_CREATE_ERROR = "EMAIL_CREATE_ERROR";

export const EMAIL_FETCH = "EMAIL_FETCH";
export const EMAIL_FETCH_SUCCESS = "EMAIL_FETCH_SUCCESS";
export const EMAIL_FETCH_ERROR = "EMAIL_FETCH_ERROR";

export const EMAIL_UPDATE = "EMAIL_UPDATE";
export const EMAIL_UPDATE_SUCCESS = "EMAIL_UPDATE_SUCCESS";
export const EMAIL_UPDATE_ERROR = "EMAIL_UPDATE_ERROR";

export const EMAIL_VIEW = "EMAIL_VIEW";
export const EMAIL_VIEW_SUCCESS = "EMAIL_VIEW_SUCCESS";
export const EMAIL_VIEW_ERROR = "EMAIL_VIEW_ERROR";

export const SMARTTAG_FETCH = "SMARTTAG_FETCH";
export const SMARTTAG_FETCH_SUCCESS = "SMARTTAG_FETCH_SUCCESS";
export const SMARTTAG_FETCH_ERROR = "SMARTTAG_FETCH_ERROR";

export const EMAIL_DELETE = "EMAIL_DELETE";
export const EMAIL_DELETE_SUCCESS = "EMAIL_DELETE_SUCCESS";
export const EMAIL_DELETE_ERROR = "EMAIL_DELETE_ERROR";
