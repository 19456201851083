import fetch from "auth/FetchInterceptor";

const CompanyService = {};

CompanyService.FetchCompany = function (paginationParams) {
  console.log(paginationParams, "service");
  return fetch({
    url: `/admin/company-list?per_page=${paginationParams.per_page}&page=${
      paginationParams.page
    }&search=${
      paginationParams.search ? encodeURIComponent(paginationParams.search) : ""
    }&status=${paginationParams.status ? paginationParams.status : ""}`,
    method: "get",
  });
};
CompanyService.CreateCompany = function (data) {
  return fetch({
    url: "/admin/company-create",
    method: "post",
    data: data,
  });
};
CompanyService.CompanyStatusChange = function (data) {
  return fetch({
    url: "/admin/company-status/" + data.data.id,
    method: "put",
    data: data.data,
  });
};
CompanyService.UpdateCompanys = function (data) {
  return fetch({
    url: "admin/company-update/" + data.id,
    method: "post",
    data: data,
  });
};
CompanyService.ViewCompany = function (data) {
  return fetch({
    url: "admin/company-view/" + data.id,
    method: "get",
  });
};
CompanyService.DeleteCompany = function (id) {
  return fetch({
    url: "admin/company-delete/" + id.id,
    method: "delete",
  });
};
export default CompanyService;
