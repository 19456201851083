import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/OrderConstants";
import OrderService from "../../services/OrderService";

export function* FetchOrdersSaga(payload) {
  try {
    const response = yield call(OrderService.FetchOrders, payload);
    yield put({ type: constant.FETCH_ORDERS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_ORDERS_ERROR, error });
  }
}
export function* FetchOrdersCountSaga(payload) {
  try {
    const response = yield call(OrderService.FetchOrdersCounts, payload);
    yield put({ type: constant.FETCH_ORDERS_COUNT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_ORDERS_COUNT_ERROR, error });
  }
}
export function* CreateOrderSaga(payload) {
  try {
    const response = yield call(OrderService.CreateOrder, payload?.formData);
    yield put({ type: constant.CREATE_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CREATE_ORDER_ERROR, error });
  }
}
export function* UpdateOrderSaga(payload) {
  try {
    const response = yield call(OrderService.UpdateOrder, payload.formData);
    yield put({ type: constant.UPDATE_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_ORDER_ERROR, error });
  }
}
export function* ViewOrderSaga(orderId) {
  try {
    const response = yield call(OrderService.ViewOrder, orderId);
    yield put({ type: constant.VIEW_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.VIEW_ORDER_ERROR, error });
  }
}
export function* DeleteOrderSaga(orderId) {
  try {
    const response = yield call(OrderService.DeleteOrder, orderId);
    yield put({ type: constant.DELETE_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DELETE_ORDER_ERROR, error });
  }
}
export function* MoveDispatchSaga(payload) {
  try {
    const response = yield call(OrderService.MoveDispatch, payload.formData);
    yield put({ type: constant.MOVE_TO_DISPATCH_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MOVE_TO_DISPATCH_ERROR, error });
  }
}
export function* CustomerListOrderSaga(customerId) {
  try {
    const response = yield call(OrderService.CustomerListOrder, customerId);
    yield put({ type: constant.FETCH_CUS_FROM_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_CUS_FROM_ORDER_ERROR, error });
  }
}
export function* PostOrderSaga(payload) {
  try {
    const response = yield call(OrderService.PostOrder, payload?.formData);
    yield put({ type: constant.FETCH_POST_ORDER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_POST_ORDER_ERROR, error });
  }
}
export function* PostOrderEmailSaga(orderId) {
  try {
    const response = yield call(OrderService.PostOrderEmail, orderId);
    yield put({ type: constant.FETCH_POST_EMAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_POST_EMAIL_ERROR, error });
  }
}
export default function* OrderSaga() {
  yield takeLatest(constant.FETCH_ORDERS_INIT, FetchOrdersSaga);
  yield takeLatest(constant.FETCH_ORDERS_COUNT_INIT, FetchOrdersCountSaga);
  yield takeLatest(constant.CREATE_ORDER_INIT, CreateOrderSaga);
  yield takeLatest(constant.UPDATE_ORDER_INIT, UpdateOrderSaga);
  yield takeLatest(constant.VIEW_ORDER_INIT, ViewOrderSaga);
  yield takeLatest(constant.DELETE_ORDER_INIT, DeleteOrderSaga);
  yield takeLatest(constant.MOVE_TO_DISPATCH, MoveDispatchSaga);
  yield takeLatest(constant.FETCH_CUS_FROM_ORDER_INIT, CustomerListOrderSaga);
  yield takeLatest(constant.FETCH_POST_ORDER_INIT, PostOrderSaga);
  yield takeLatest(constant.FETCH_POST_EMAIL_INIT, PostOrderEmailSaga);
}
