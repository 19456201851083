import * as constant from "../constants/OrderConstants";

const initialState = {
  order_list: [],
  order: null,
  loading: false,
  error: null,
  create_success: false,
  update_success: false,
  move_success: false,
  status_counts: null,
  delete_success: null,
  customerorder_success: null,
  post_success: false,
  postorder_email: null,
};

const OrderReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case constant.FETCH_ORDERS_INIT:
    case constant.CREATE_ORDER_INIT:
    case constant.UPDATE_ORDER_INIT:
    case constant.VIEW_ORDER_INIT:
    case constant.DELETE_ORDER_INIT:
    case constant.MOVE_TO_DISPATCH:
    case constant.FETCH_CUS_FROM_ORDER_INIT:
    case constant.FETCH_POST_ORDER_INIT:
    case constant.FETCH_POST_EMAIL_INIT:
      return {
        ...state,
        loading: true,
        create_success: false,
        update_success: false,
      };

    case constant.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        order_list: response.data,
        order: null,
      };

    case constant.FETCH_ORDERS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        status_counts: response.data,
      };

    case constant.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        create_success: response,
      };

    case constant.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        update_success: response,
      };

    case constant.VIEW_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: response.data,
      };

    case constant.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        delete_success: response,
      };

    case constant.MOVE_TO_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        move_success: response,
      };
    case constant.FETCH_CUS_FROM_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        customerorder_success: response,
      };

    case constant.FETCH_POST_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        create_success: response,
      };
    case constant.FETCH_POST_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: response.data,
      };
    case constant.FETCH_ORDERS_ERROR:
    case constant.CREATE_ORDER_ERROR:
    case constant.UPDATE_ORDER_ERROR:
    case constant.VIEW_ORDER_ERROR:
    case constant.DELETE_ORDER_ERROR:
    case constant.MOVE_TO_DISPATCH_ERROR:
    case constant.FETCH_CUS_FROM_ORDER_ERROR:
    case constant.FETCH_POST_ORDER_ERROR:
    case constant.FETCH_POST_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: response,
      };
    default:
      return state;
  }
};

export default OrderReducer;
