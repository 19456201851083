import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/DispatchConstants";
import DispatchService from "../../services/DispatchService";

export function* FetchDispatchesSaga(payload) {
  try {
    const response = yield call(DispatchService.FetchDispatches, payload);
    yield put({ type: constant.FETCH_DISPATCH_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_DISPATCH_ERROR, error });
  }
}
export function* FetchDispatchesCountSaga(payload) {
  try {
    const response = yield call(DispatchService.FetchDispatchCounts, payload);
    yield put({ type: constant.FETCH_DISPATCH_COUNTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_DISPATCH_COUNTS_ERROR, error });
  }
}
export function* ViewDispatchSaga(dispatchId) {
  try {
    const response = yield call(DispatchService.ViewDispatch, dispatchId);
    yield put({ type: constant.VIEW_DISPATCH_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.VIEW_DISPATCH_ERROR, error });
  }
}
export function* DeleteDispatchSaga(dispatchId) {
  try {
    const response = yield call(DispatchService.DeleteDispatch, dispatchId);
    yield put({ type: constant.DELETE_DISPATCH_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DELETE_DISPATCH_ERROR, error });
  }
}
export function* DeleteExpenseSaga(expenseId) {
  try {
    const response = yield call(DispatchService.DeleteExpense, expenseId);
    yield put({ type: constant.DELETE_EXPENSE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DELETE_EXPENSE_ERROR, error });
  }
}
export function* OrderUpdateSaga(payload) {
  try {
    const response = yield call(DispatchService.OrderUpdate, payload.formData);
    yield put({ type: constant.DISPATCH_ORDER_STATUS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DISPATCH_ORDER_STATUS_ERROR, error });
  }
}
export function* ShipmentUpdateSaga(payload) {
  try {
    const response = yield call(
      DispatchService.ShipmentUpdate,
      payload.formData
    );
    yield put({
      type: constant.DISPATCH_ORDER_SHIPMENT_STATUS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.DISPATCH_ORDER_SHIPMENT_STATUS_ERROR, error });
  }
}
export function* CreateDispatchContact(payload) {
  try {
    const response = yield call(
      DispatchService.CreateDispatchContact,
      payload.formData
    );
    yield put({ type: constant.DISPATCH_CONTACT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DISPATCH_CONTACT_ERROR, error });
  }
}
export function* CreateAccountRep(payload) {
  try {
    const response = yield call(
      DispatchService.CreateAccountRep,
      payload.formData
    );
    yield put({ type: constant.ACCOUNT_REP_CREATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DISPATCH_CONTACT_ERROR, error });
  }
}
export function* CreateSalesRep(payload) {
  try {
    const response = yield call(
      DispatchService.CreateSalesRep,
      payload.formData
    );
    yield put({ type: constant.SALES_REP_CREATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.SALES_REP_CREATE_ERROR, error });
  }
}
export function* DispatchCompletionUpdateSaga(payload) {
  try {
    const response = yield call(
      DispatchService.DispatchCompletionUpdate,
      payload.formData
    );
    yield put({ type: constant.DISPATCH_COMPLETION_UPDATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DISPATCH_COMPLETION_UPDATE_ERROR, error });
  }
}
export function* CreateExpenseSaga(payload) {
  try {
    const response = yield call(DispatchService.CreateExpense, payload);
    yield put({ type: constant.FETCH_ADDEXPENSE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_ADDEXPENSE_ERROR, error });
  }
}
export function* addDispatchDocumentSaga(payload) {
  try {
    const response = yield call(
      DispatchService.CreateDispatchdocument,
      payload.data
    );
    yield put({ type: constant.CREATE_DISPATCH_DOCUMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CREATE_DISPATCH_DOCUMENT_ERROR, error });
  }
}
export function* FetchDispatchesData(payload) {
  try {
    const response = yield call(DispatchService.FetchDispatchData, payload);
    yield put({ type: constant.FETCH_DISPATCH_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_DISPATCH_COUNTS_ERROR, error });
  }
}
export function* UpdateDispatchData(payload) {
  try {
    const response = yield call(DispatchService.UpdateDispatchData, payload);
    yield put({ type: constant.UPDATE_DISPATCH_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_DISPATCH_DATA_ERROR, error });
  }
}

export function* UpdateDriverTrailerDataSaga(payload) {
  try {
    const response = yield call(
      DispatchService.UpdateDriverTrailerData,
      payload.formData
    );
    yield put({ type: constant.UPDATE_TRAILER_DRIVER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_TRAILER_DRIVER_ERROR, error });
  }
}
export function* TripManifestEmailSaga(orderId) {
  try {
    const response = yield call(DispatchService.TripManifestEmail, orderId);
    yield put({ type: constant.FETCH_TRIP_EMAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_TRIP_EMAIL_ERROR, error });
  }
}

export function* TripManifestSendEmailSaga(payload) {
  try {
    const response = yield call(
      DispatchService.TripManifestSendEmail,
      payload.formData
    );
    yield put({ type: constant.FETCH_TRIP_EMAIL_SEND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_TRIP_EMAIL_SEND_ERROR, error });
  }
}

export function* LoadConfirmationSendEmailSaga(payload) {
  try {
    const response = yield call(
      DispatchService.LoadConfirmationSendEmail,
      payload.formData
    );
    yield put({ type: constant.FETCH_LOAD_EMAIL_SEND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_LOAD_EMAIL_SEND_ERROR, error });
  }
}
export default function* DispatchSaga() {
  yield takeLatest(constant.FETCH_DISPATCH_INIT, FetchDispatchesSaga);
  yield takeLatest(
    constant.FETCH_DISPATCH_COUNTS_INIT,
    FetchDispatchesCountSaga
  );

  yield takeLatest(constant.VIEW_DISPATCH_INIT, ViewDispatchSaga);
  yield takeLatest(constant.DISPATCH_CONTACT_INIT, CreateDispatchContact);
  yield takeLatest(constant.ACCOUNT_REP_CREATE_INIT, CreateAccountRep);
  yield takeLatest(constant.SALES_REP_CREATE_INIT, CreateSalesRep);
  yield takeLatest(constant.DELETE_DISPATCH_INIT, DeleteDispatchSaga);
  yield takeLatest(constant.DELETE_EXPENSE_INIT, DeleteExpenseSaga);
  yield takeLatest(constant.DISPATCH_ORDER_STATUS_INIT, OrderUpdateSaga);
  yield takeLatest(
    constant.DISPATCH_COMPLETION_UPDATE_INIT,
    DispatchCompletionUpdateSaga
  );
  yield takeLatest(
    constant.DISPATCH_ORDER_SHIPMENT_STATUS_INIT,
    ShipmentUpdateSaga
  );
  yield takeLatest(constant.FETCH_ADDEXPENSE_INIT, CreateExpenseSaga);
  yield takeLatest(constant.CREATE_DISPATCH_DOCUMENT, addDispatchDocumentSaga);
  yield takeLatest(constant.FETCH_DISPATCH_DATA_INIT, FetchDispatchesData);
  yield takeLatest(constant.UPDATE_DISPATCH_DATA_INIT, UpdateDispatchData);
  yield takeLatest(
    constant.UPDATE_TRAILER_DRIVER_INIT,
    UpdateDriverTrailerDataSaga
  );
  yield takeLatest(constant.FETCH_TRIP_EMAIL_INIT, TripManifestEmailSaga);
  yield takeLatest(
    constant.FETCH_TRIP_EMAIL_SEND_INIT,
    TripManifestSendEmailSaga
  );
  yield takeLatest(
    constant.FETCH_LOAD_EMAIL_SEND_INIT,
    LoadConfirmationSendEmailSaga
  );
}
